<template>
  <el-card>
    <el-form
      ref="form"
      :model="form"
      :rules="exportRules"
      labelPosition="top"
      label-width="80px"
      :hide-required-asterisk="true"
    >
      <el-form-item>
        <label class="label" slot="label">{{ $t('export.station.pick') }}</label>
        <p v-if="!instrumentList.length">{{ $t('export.station.none') }}</p>
        <div v-else>
          <el-cascader
            v-model="selectInstrumentList"
            style="min-width:200px"
            :options="projectOptions"
            :props="props"
            :collapse-tags="true"
            :show-all-levels="false"
            :placeholder="$t('chart.instrument.pick')"
            @change="getCascaderInstrumentList"
          ></el-cascader>
        </div>
      </el-form-item>
      <el-form-item>
        <label class="label" slot="label">{{ $t('export.type.pick') }}</label>
        <el-radio-group v-model="form.type">
          <el-radio-button v-for="type in typeList" :key="type" :label="type">{{
            $t(`export.type.${type}`)
          }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <label class="label" slot="label">{{ $t('export.interval.pick') }}</label>
        <el-radio-group v-model="form.interval">
          <el-radio-button
            v-for="interval in intervalList"
            :label="interval.type"
            :key="interval.type"
            :disabled="!check(interval.type)"
            >{{ $t(`export.interval.${interval.type}`) }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="date">
        <label class="label" slot="label">{{ $t('export.date.pick') }}</label>
        <el-date-picker
          v-model="form.date"
          type="datetimerange"
          :start-placeholder="$t('export.date.start')"
          :end-placeholder="$t('export.date.end')"
          :picker-options="datePickerOptions"
          format="yyyy/MM/dd HH:mm"
          :default-time="['00:00:00']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <label class="label" slot="label">{{ $t('export.tips.title') }}</label>
        <div>
          {{ $t('export.tips.0') }}<br>
          {{ $t('export.tips.1') }}<br>
          {{ $t('export.tips.2') }}<br>
          {{ $t('export.tips.3') }}<br>
        </div><br>
        <span
          class="button is-primary common-confirm-button"
          :disabled="disableSubmit"
          :class="{ 'is-loading': isDownloading }"
          @click="submitForm"
          >{{ $t('export.download') }}</span
        >
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    useUrl: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      project: null,
      form: {
        instrument: [],
        type: 'measure',
        interval: 'daily',
        date: [
          moment()
            .subtract(7, 'd')
            .startOf('day')
            .toISOString(),
          moment()
            .toISOString(),
        ],
      },
      selectedInstrumentNum: 0,
      selectedDays: 0,
      checkAll: true,
      isLoading: false,
      isDownloading: false,
      isIndeterminate: true,
      instrumentList:[],
      selectInstrumentList: [],
      projectOptions: [],
      //stationIdList: [],
      //stationList: [],
      //stationInstrument: null,
      intervalList: [{ type: 'daily' }, { type: 'hour' }, { type: 'all' }],
      exportRules: {
        date: [
          {
            type: 'array',
            required: true,
            message: this.$t('chart.error.date'),
            trigger: 'change',
          },
        ],
      },
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      
      props: {
        multiple: true,
        //expandTrigger: 'hover',
        label: 'label',
        value: 'value',
        children: 'children',
      },
    };
  },
  computed: {
    selectedProject() {
      if(this.project) return this.project;
      return this.$store.getters['project/selectedProject'];
    },
    disableSubmit() {
      return this.form.instrument.length === 0 || this.form.date.length === 0;
    },
    typeList() {
      //console.log(this.selectedProject.config.export.display);
      return this.selectedProject.config.export.display;
    },
  },
  mounted() {
    //console.log(`test ${this.useUrl}`);
    if(this.useUrl){
      this.setProject().then(()=>{
        this.initCascaderSelector();
      });
    }
    else{
      this.initCascaderSelector();
    }
    //console.log(this.form.date);
  },
  methods: {
    setProject() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: '',
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      })
        .then(res => {
          this.project = res.data[0];
        })
        .catch(this.$notifyError);
    },
    initCascaderSelector() {
      this.projectOptions = [];
      this.form.instrument = [];
      this.instrumentList = [];
      this.dataList = [];
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/area-station-instrument`,
        useUrl: this.useUrl ? 'https://api-thsr-rain.geosupply.com.tw/' : null,
      })
        .then(res => {
          this.projectOptions = res.data.area.map(area => {
            const obj = {
              label: area.name,
              disabled: area.station.length === 0,
              children: area.station.map(station => {
                const obj = {
                  label: station.name,
                  disabled: station.instrument.length === 0,
                  children: station.instrument.map(instrument => {
                    const obj = {
                      label: instrument.latestVersion.name,
                      value: instrument._id,
                    };
                    this.instrumentList.push(instrument);
                    return obj;
                  }),
                };
                return obj;
              }),
            };
            return obj;
          });
        })
        .catch(error => {
          console.log(error);
          this.$notifyError(this.$t('chart.error.loading'), error.response.data.message);
        });
    },
    getCascaderInstrumentList() {
      const usedInstruments = this.selectInstrumentList.map(ele => ele[2]);
      //console.log(this.instrumentList);
      this.form.instrument = usedInstruments;
      if(!this.check()){
        this.$notify({
          title: '超出限制',
          message: `選擇天數：${this.selectedDays} 天<br>選擇儀器：${this.selectedInstrumentNum} 個`,
          dangerouslyUseHTMLString: true,
          type: 'warning',
        });
      }
    },
    check(interval='') {
      if(interval === '') {
        interval = this.form.interval;
      }
      let totalInstrument = this.form.instrument.length;
      this.selectedInstrumentNum = totalInstrument;

      let totalDays = 0;
      let date1 = new Date(this.form.date[0]);
      let date2 = new Date(this.form.date[1]);
      let diff = date2.getTime() - date1.getTime();
      totalDays = parseInt(diff / (1000 * 3600 * 24));
      this.selectedDays = totalDays;

      //if (this.$store.getters['user/isAdmin'])
      //  return true;

      if (totalInstrument > 60) return false;

      switch(interval) {
        case 'all':
          if(totalDays > 90)
            return false;
          break;
        default:
      }

      return true;
    },
    submitForm() {
      //console.log(this.form);
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isLoading = true;
          if (this.check()) {
            await this.downloadOutput();
          } else {
            this.$notify({
              title: '超出限制',
              message: `選擇天數：${this.selectedDays} 天<br>選擇儀器：${this.selectedInstrumentNum} 個`,
              dangerouslyUseHTMLString: true,
              type: 'warning',
            });
          }
          this.isLoading = false;
        } else {
          this.isLoading = false;
          return false;
        }
      });
    },
    downloadOutput() {
      this.isDownloading = true;
      //console.log(this.form.date);
      //若輸出的值為速率，則先將tpye轉換成reading2(或reading1)再呼叫API
      this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/output/instrument-export`,
        data: this.form,
        responseType: 'blob',
        useUrl: this.useUrl ? 'https://api-thsr-rain.geosupply.com.tw/' : null,
      })
        .then(async res => {
          if(this.form.type === 'measure' || this.form.type === 'raw')
            await this.downloads(res.data);
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isDownloading = false;
        });
    },
    downloads(content) {
      if (!content) {
        return;
      }
      const [startTime, endTime] = [
        moment(this.form.date[0]).format('MM/DD HH:mm'),
        moment(this.form.date[1]).format('MM/DD HH:mm'),
      ];
      // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //   window.navigator.msSaveOrOpenBlob(content, `${startTime}-${endTime}.csv`);
      // } else {
      const csv = '\uFEFF' + content;
      const url = window.URL.createObjectURL(new Blob([csv], { type: 'text/plain' }));
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.setAttribute('download', `${startTime}-${endTime}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      // }
    },
    rateCompute(data, afterData){
      let target = this.dataToTable(data);
      let result = this.$lodash.cloneDeep(target);
      let computedObj = this.dataToTable(afterData);

      //console.log(target);
      //console.log(computedObj);

      let typeOfRate = '';
      if(this.form.type === 'velocity24Hr')
      {
        typeOfRate = 'd';
      }
      else if(this.form.type === 'velocity1Hr'){
        typeOfRate = 'h';
      }
      for(let ts of target.timestamp){
        //console.log(ts);
        //console.log(ts.replaceAll('"',''));
        const afterTs = moment(ts.replaceAll('"','')).subtract(1,typeOfRate).format('YYYY/MM/DD HH:mm');
        //console.log(afterTs);
        let getRateComputedObj = computedObj.timestamp.includes(`"${afterTs}"`);
        result[ts] = target[ts].map((d,index)=>{
          if(getRateComputedObj){
            let a = d.replaceAll('\r','');
            let b = computedObj[`"${afterTs}"`][index].replaceAll('\r','');
            if(!this.isNumber(a)) return '';
            if(!this.isNumber(b)) return '';
            //if(d.length==0) return '';
            //if(computedObj[`"${afterTs}"`][index].length==0) return '';
            return Number(a) - Number(b);
          }
          else return '';
        });
      }
      
      //console.log(result);
      result = this.tableToData(result);
      return result;
    },
    isNumber(val){
      var regPos = /^(-|\+)?\d+(\.\d+)?$/; //判断是否是数字。
      if(regPos.test(val) ){
        return true;
      }else{
        //console.log(val);
        return false;
      }
    },
    dataToTable(data){
      let row = data.split('\n');
      let result = {};
      let header = row[0].split(',');
      result.header = header;
      result.timestamp = [];
      for(let i = 1; i < row.length; i++){
        let tmp = row[i].split(',');
        result[tmp[0]] = tmp.slice(1);
        result.timestamp.push(tmp[0]);
      }
      return result;
    },
    tableToData(table){
      let result = '';
      table.header.forEach(_element => {
        let element = _element.replaceAll('\r','');
        result += `${element},`;
      });
      result += '\n';
      table.timestamp.forEach(time => {
        result += `${time},`;
        table[time].forEach(data=>{
          result += `${data},`;
        });
        result += '\n';
      });
      return result;
    },
  },
};
</script>

<style lang="stylus" scoped>
.el-radio-button.is-active
  z-index 10
</style>
